<script>
import NotificationSettingsBus from '../../../event_bus/index.js'
import { useI18n } from 'vue-i18n'

export default {
  name: 'NotificationSettingsRuleForm',
  props: {
    current_rule: Object,
    power_plants: Array,
    responsibles: Array,
    operational_status_types: Array,
    currency_codes: Array
  },
  data() {
    return {
      rule: {},
      conditions: [],
      formErrors: {},
    }
  },
  setup() {
    const { locale, getLocaleMessage, t } = useI18n()
    return { locale, getLocaleMessage, t }
  },
  created() {
    this.prefillFormData()
    NotificationSettingsBus.$on('save-rule-success', this.handleSaveSuccess)
    NotificationSettingsBus.$on('delete-success', this.handleDeleteSuccess)
    NotificationSettingsBus.$on('save-rule-responsible-success', this.handleSaveRuleResponsibleSuccess)
  },
  computed: {
    weekdays() {
      const loc = this.locale || 'en'
      const messages = this.getLocaleMessage(loc)
      const dayNames = messages.date.day_names
      return [
        { key: 'dow_1', name: dayNames[1] },
        { key: 'dow_2', name: dayNames[2] },
        { key: 'dow_3', name: dayNames[3] },
        { key: 'dow_4', name: dayNames[4] },
        { key: 'dow_5', name: dayNames[5] },
        { key: 'dow_6', name: dayNames[6] },
        { key: 'dow_0', name: dayNames[0] }
      ]
    },
    selectedWeekdays: {
      get() {
        return this.weekdays.filter(day => this.rule[day.key]);
      },
      set(newValues) {
        this.weekdays.forEach(day => { this.rule[day.key] = false; });
        newValues.forEach(day => { this.rule[day.key] = true; });
      }
    },
    translatedOperationalStatuses() {
      return this.operational_status_types.map(ost => ({
        id: ost.id,
        name: this.$t(`om_dashboard.status.solar.${ost.name}`)
      }))
    },
    isEdit() {
      return this.current_rule && this.current_rule.id
    },
    conditionOptions() {
      return [
        { title: this.$t('notification_system.condition_selection.unavailable_power'), value: 'min_unav_kw' },
        { title: this.$t('notification_system.condition_selection.energy_loss'), value: 'min_loss_mwh' },
        { title: this.$t('notification_system.condition_selection.yield_loss'), value: 'min_currency_loss' },
        { title: this.$t('notification_system.condition_selection.event_duration'), value: 'min_duration_h' }
      ]
    }
  },
  methods: {
    handleSaveSuccess(payload, isEdit) {
      console.log("handleSaveSuccess called with:", payload, isEdit);
    },
    handleDeleteSuccess(deletedRuleId) {
      console.log("handleDeleteSuccess called with:", deletedRuleId);
    },
    handleSaveRuleResponsibleSuccess(payload) {
      console.log("handleSaveRuleResponsibleSuccess called with:", payload);
    },
    removeChipResponsibles(itemToRemove) {
      this.rule.responsibles = this.rule.responsibles.filter(item => item.id !== itemToRemove.id)
    },
    prefillFormData() {
      this.rule = {
        name: this.current_rule?.name || '',
        power_plants: this.current_rule?.power_plants || [],
        responsibles: this.current_rule?.responsibles || [],
        operational_status_types: this.current_rule?.operational_status_types || [],
        dow_0: this.current_rule?.dow_0 || false,
        dow_1: this.current_rule?.dow_1 || false,
        dow_2: this.current_rule?.dow_2 || false,
        dow_3: this.current_rule?.dow_3 || false,
        dow_4: this.current_rule?.dow_4 || false,
        dow_5: this.current_rule?.dow_5 || false,
        dow_6: this.current_rule?.dow_6 || false,
        immediate: this.current_rule?.immediate || null,
        min_unav_kw: this.current_rule?.min_unav_kw || null,
        min_loss_mwh: this.current_rule?.min_loss_mwh || null,
        min_currency_loss: this.current_rule?.min_currency_loss || null,
        min_duration_h: this.current_rule?.min_duration_h || null,
        currency: this.current_rule?.currency || null,
        or_operator: this.current_rule?.or_operator || false,
        all_plants: this.current_rule?.all_plants || null,
        active: this.current_rule?.active || null
      }

      this.conditionOptions.forEach(option => {
        if (this.rule[option.value] !== undefined && this.rule[option.value] !== null) {
          this.conditions.push({ value: option.value, title: option.title })
        }
      })
    },
    addCondition() {
      if (this.conditions.length < this.conditionOptions.length) {
        this.conditions.push({
          value: null,
          title: this.$t('notification_system.form_options.select_condition_type')
        })
      }
    },
    updateCondition(index, selectedValue) {
      const selectedOption = this.conditionOptions.find(option => option.value === selectedValue)
      if (selectedOption) {
        this.conditions.splice(index, 1, {
          value: selectedOption.value,
          title: selectedOption.title
        })
        this.rule[selectedOption.value] = null
      }
    },
    removeCondition(index) {
      const conditionValue = this.conditions[index].value
      this.conditions.splice(index, 1)
      if (conditionValue && this.rule.hasOwnProperty(conditionValue)) {
        this.rule[conditionValue] = null
      }
    },
    remainingConditionOptions(selectedValue) {
      const usedValues = this.conditions.filter(c => c.value !== selectedValue).map(c => c.value)
      return this.conditionOptions.filter(option => !usedValues.includes(option.value))
    },
    conditionIsSet(field) {
      return this.conditions.some(condition => condition.value === field)
    },
    saveRule() {
      const payload = {
        notification_rule: {
          ...this.rule,
          power_plant_ids: this.rule.power_plants.map(plant => plant.id),
          responsible_ids: this.rule.responsibles.map(responsible => responsible.id),
          operational_status_type_ids: this.rule.operational_status_types.map(status => status.id)
        }
      }

      let apiUrl, request

      if (this.isEdit) {
        apiUrl = `/admin/notification_rules/${this.current_rule.id}`
        request = this.axios.put(apiUrl, payload)
      } else {
        apiUrl = '/admin/notification_rules'
        request = this.axios.post(apiUrl, payload)
      }

      this.formErrors = {}
      request
        .then(response => {
          NotificationSettingsBus.$emit('save-rule-success', response.data, this.isEdit)
          this.goBack()
        })
        .catch(error => {
          if (error.response && error.response.data) {
            this.formErrors = error.response.data || {}
          } else {
            console.error(error)
          }
        })
    },
    deleteRule() {
      if (this.isEdit) {
        if (!confirm(this.$t('notification_system.form_options.delete_rule'))) { return; }
        const apiUrl = `/admin/notification_rules/${this.current_rule.id}`
        this.axios
          .delete(apiUrl)
          .then(() => {
            NotificationSettingsBus.$emit('delete-success', this.current_rule.id)
            this.goBack()
          })
          .catch(error => { console.error('Error deleting rule:', error); })
      }
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  beforeUnmount() {
    NotificationSettingsBus.$off('save-rule-success', this.handleSaveSuccess)
    NotificationSettingsBus.$off('delete-success', this.handleDeleteSuccess)
    NotificationSettingsBus.$off('save-rule-responsible-success', this.handleSaveRuleResponsibleSuccess)
  }
}
</script>

<template lang="pug">
v-card
  v-toolbar(density="compact" flat)
    v-btn(icon color="#344054" @click="goBack")
      v-icon mdi-chevron-left
    v-toolbar-title {{ $t('notification_system.form_options.notification_rule_details') }}
  v-divider
  v-card-text
    v-container
      v-row
        v-col(cols="12" class="py-0")
          v-text-field(
            v-model="rule.name"
            id="rule_name"
            :label="$t('notification_system.form_options.name_of_notification')"
            variant="outlined"
            density="compact"
            :error-messages="formErrors['name']"
          )
        v-col(cols="12" class="py-0" style="margin-left: 8px;")
          v-switch(
            v-model="rule.all_plants"
            :label="$t('notification_system.form_options.all_plants')"
            inset
            color="info"
          )
        v-col(cols="12" class="py-0" v-if="!rule.all_plants")
          v-combobox(
            v-model="rule.power_plants"
            :items="power_plants"
            item-title="name"
            item-value="id"
            :label="$t('notification_system.form_options.select_power_plants')"
            variant="outlined"
            multiple
            return-object
            chips
            attach
            closable-chips
            :menu-props="{ maxHeight: '400px' }"
            :error-messages="formErrors['power_plants']"
          )
        v-col(cols="12" class="py-0")
          v-combobox(
            v-model="rule.responsibles"
            :items="responsibles"
            item-title="name"
            item-value="id"
            :label="$t('notification_system.form_options.select_notification_recipients')"
            variant="outlined"
            multiple
            small-chips
            attach
            deletable-chips
            :menu-props="{ maxHeight: '400px' }"
            :error-messages="formErrors['responsibles']"
          )
            template(v-slot:selection="{ item, index }")
              template(v-if="'active_for_rule' in item && !item.active_for_rule && isEdit")
                v-tooltip(location="bottom")
                  template(v-slot:activator="{ props }")
                    v-chip(
                      :key="index"
                      color="black"
                      size="small"
                      close
                      @click:close="removeChipResponsibles(item)"
                      v-bind="props"
                    )
                      | {{ item.title }}
                  span {{ $t('notification_system.form_options.responsible_inactive_for_rule', { name: item.name }) }}
              template(v-else)
                v-chip(
                  :key="index"
                  color="black"
                  size="small"
                  close
                  @click:close="removeChipResponsibles(item)"
                )
                  | {{ item.title }}
        v-col(cols="12" class="py-0")
          v-combobox(
            v-model="rule.operational_status_types"
            :items="translatedOperationalStatuses"
            item-title="name"
            item-value="id"
            :label="$t('notification_system.form_options.select_operational_statuses')"
            variant="outlined"
            multiple
            return-object
            chips
            attach
            closable-chips
            :menu-props="{ maxHeight: '400px' }"
            :error-messages="formErrors['operational_status_type_ids']"
          )
        v-col(cols="12" class="py-0" style="margin-left: 8px;")
          v-switch(
            v-model="rule.immediate"
            :label="$t('notification_system.form_options.receive_notifications_immediately')"
            inset
            color="info"
          )
        v-col(cols="12" v-if="!rule.immediate" class="py-0")
          v-combobox(
            v-model="selectedWeekdays"
            :items="weekdays"
            item-title="name"
            item-value="key"
            return-object
            :label="$t('notification_system.form_options.receive_summaries_on')"
            variant="outlined"
            multiple
            attach
            chips
            closable-chips
            :menu-props="{ maxHeight: '400px' }"
            :error-messages="formErrors['week_days']"
          )
        v-col(cols="12" class="pt-0 pb-2")
          v-select(
            v-model="rule.currency"
            :items="currency_codes"
            :label="$t('notification_system.form_options.show_yield_losses_in_currency')"
            variant="outlined"
            density="compact"
          )
        v-col(cols="12" class="py-0" style="margin-left: 8px;")
          v-switch(
            v-model="rule.or_operator"
            :label="$t('notification_system.form_options.match_any_condition')"
            inset
            color="info"
          )
          p {{ $t('notification_system.form_options.any_all_text', { anyall: rule.or_operator ? $t('notification_system.form_options.any') : $t('notification_system.form_options.all') } ) }}
      v-row(v-for="(condition, index) in conditions", :key="index")
        span(v-if="index > 0")
          v-col(cols="12" class="py-2")
            | {{ rule.or_operator ? $t('notification_system.form_options.or') : $t('notification_system.form_options.and') }}
        v-col(cols="12" class="py-0")
          v-card(variant="outlined")
            v-card-title(class="subtitle-1 d-flex justify-space-between align-center")
              span(style="font-size: 0.9em;") Condition {{ index + 1 }}
              v-btn(icon size="x-small" @click="removeCondition(index)")
                v-icon mdi-close
            v-card-text
              v-select(
                v-model="conditions[index].value"
                :items="remainingConditionOptions(condition.value)"
                :label="$t('notification_system.form_options.select_condition_type')"
                variant="outlined"
                :error-messages="formErrors[condition.value]"
              )
              v-text-field(
                v-model="rule[condition.value]"
                :label="$t('notification_system.form_options.threshold', { condition_title: condition.title ? condition.title : '...'})"
                variant="outlined"
                type="number"
                :error-messages="formErrors[condition.value]"
              )
      v-row
        v-col(cols="12" class="py-2")
          v-card(
            variant="outlined"
            class="d-flex justify-center align-center"
            @click.stop="addCondition"
            :disabled="conditions.length >= conditionOptions.length"
            tabindex="-1"
          )
            v-card-text(class="text-center")
              v-icon(medium) mdi-plus
              span(class="subtitle-1") {{ $t('notification_system.form_options.add_new_condition') }}
      v-row
        v-col(cols="12" class="py-2" style="margin-left: 8px;")
          v-switch(
            v-model="rule.active"
            :label="$t('notification_system.form_options.active')"
            inset
            color="info"
          )
  v-card-actions
    v-btn(color="#344054" variant="flat" text @click="deleteRule" v-if="isEdit") {{ $t('notification_system.form_options.delete') }}
    v-btn(@click="saveRule" variant="flat" color="#0BA5EC" depressed class="ma-2 white--text") {{ $t('notification_system.form_options.save') }}
</template>

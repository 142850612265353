<script>
import NotificationSettingsBus from '../../../event_bus/index.js'

export default {
  name: 'NotificationSettingsGeneral',
  props: {
    current_user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rules: [],
      power_plants: [],
      responsibles: [],
      operational_status_types: [],
      currency_codes: [],
      loading: true
    }
  },
  methods: {
    editRule(rule) {
      this.$router.push({
        name: 'notification_settings_rule',
        query: {
          rule,
          power_plants: this.power_plants,
          responsibles: this.responsibles,
          operational_status_types: this.operational_status_types,
          currency_codes: this.currency_codes
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    handleSaveSuccess(savedRule, fromEdit) {
      if (fromEdit) {
        const index = this.rules.findIndex(item => item.id === savedRule.id)
        if (index !== -1) this.rules.splice(index, 1, savedRule)
      } else {
        this.rules.push(savedRule)
      }
    },
    handleDeleteSuccess(deletedRuleId) {
      this.rules = this.rules.filter(rule => rule.id !== deletedRuleId)
    },
    handleSaveRuleResponsibleSuccess({ responsible_id, notification_rule_id, active }) {
      const ruleIndex = this.rules.findIndex(rule => rule.id === notification_rule_id)
      if (ruleIndex !== -1) {
        const updatedRule = { ...this.rules[ruleIndex] }
        updatedRule.responsibles = updatedRule.responsibles.map(responsible => {
          if (responsible.id === responsible_id) {
            return { ...responsible, active_for_rule: active }
          }
          return responsible
        })
        this.rules.splice(ruleIndex, 1, updatedRule)
      }
    },
    fetchRules() {
      const apiUrl = '/admin/notification_rules'
      this.loading = true
      this.axios
        .get(apiUrl)
        .then(response => {
          const { rules, power_plants, responsibles, operational_status_types, currency_codes } = response.data
          this.rules = rules
          this.power_plants = power_plants
          this.responsibles = responsibles
          this.operational_status_types = operational_status_types
          this.currency_codes = currency_codes
          this.loading = false
        })
        .catch(error => {
          console.error(error)
          this.loading = false
        })
    }
  },
  created() {
    NotificationSettingsBus.$on('save-rule-success', this.handleSaveSuccess)
    NotificationSettingsBus.$on('delete-success', this.handleDeleteSuccess)
    NotificationSettingsBus.$on('save-rule-responsible-success', this.handleSaveRuleResponsibleSuccess)
    this.fetchRules()
  }
}
</script>

<template lang="pug">
v-card
  v-toolbar(density="compact" flat)
    v-btn(icon color="#344054" @click="goBack")
      v-icon mdi-chevron-left
    v-toolbar-title {{ $t('notification_system.company_notifications', { company_name: current_user.current_company_name }) }}
  v-divider
  v-card-text
    div(v-if="!loading && rules.length === 0")
      | {{ $t('notification_system.no_rules') }}
    v-list(density="compact")
      v-list-item(v-for="(rule, index) in rules" :key="rule.id" link @click="editRule(rule)" :ripple="false")
        v-list-item-title {{ rule.name }}
        v-list-item-action(class="align-self-center")
          v-tooltip(location="bottom")
            template(v-slot:activator="{ props }")
              v-icon(:color="rule.active ? 'info' : 'grey'", v-bind="props") mdi-send-clock-outline
            span {{ rule.active ? $t('notification_system.sends_notifications', { days: rule.active_days }) : $t('notification_system.rule_disabled') }}
      v-btn(text @click="editRule({})" v-if="!loading")
        | + {{ $t('notification_system.add_notification_rule') }}
</template>

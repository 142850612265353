import * as Sentry from '@sentry/vue'

export function setupSentry(app, router) {
  if (process.env.NODE_ENV !== 'development') {
    if (window.sentry_dsn !== '') {
      Sentry.init({
        app,
        dsn: window.sentry_dsn,
        environment: window.sentry_environment,
        tracesSampleRate: 1.0,
        integrations: [
          Sentry.browserTracingIntegration(),
          Sentry.replayIntegration({
              maskAllText: false,
              blockAllMedia: false
          }),
          Sentry.replayCanvasIntegration()
        ],
        // Session Replay
        replaysSessionSampleRate: 0.0,
        replaysOnErrorSampleRate: 1.0
      })

      Sentry.configureScope((scope) => {
        scope.setUser({
          id: window.user_id,
          email: window.user_email,
          first_name: window.user_first_name,
          last_name: window.user_last_name
        })
      })
    }
  }
}

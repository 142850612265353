import { createApp } from 'vue'
import router from '../router/index.js'
import App from '../app.vue'
import Toast from 'vue-toastification'
import I18n from '../plugins/vue_i18n.js'
import axiosPlugin from '../plugins/axios.js'
import Vuetify from '../plugins/vuetify.js'
import { setupHighcharts } from '../plugins/highcharts.js'
import { setupSentry } from '../plugins/sentry'
import '../plugins/windy'
import 'vuetify/dist/vuetify.css'
import 'easymde/dist/easymde.min.css'
import 'vue-toastification/dist/index.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'

// Vuetify fonts
const interFontLink = document.createElement('link')
interFontLink.href =
  'https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Roboto:wght@100;300;400;500;700;900&display=swap'
interFontLink.rel = 'stylesheet'
document.head.appendChild(interFontLink)

const robotoFontLink = document.createElement('link')
robotoFontLink.href = 'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900'
robotoFontLink.rel = 'stylesheet'
document.head.appendChild(robotoFontLink)

const mdiLink = document.createElement('link')
mdiLink.href = 'https://cdn.jsdelivr.net/npm/@mdi/font@7.0.96/css/materialdesignicons.min.css'
mdiLink.rel = 'stylesheet'
document.head.appendChild(mdiLink)

// Components that are shared or need to be available in the Rails templates
import * as global from '../components/global.js'
import HighchartsVue from 'highcharts-vue'

const app = createApp(App)

for (const [key, value] of Object.entries(global.default)) {
  app.component(key, value)
}

app.use(Toast)
app.use(router)
app.use(axiosPlugin)
setupHighcharts()
setupSentry(app, router)
app.use(HighchartsVue)
app.use(Vuetify)
app.use(I18n)

// Make the window object available in Vue templates
app.config.globalProperties.$window = window

app.config.globalProperties.$filters = {
  pretty(val, indent = 2) {
    console.log('val: ', val)
    if (val === 'null') {
      console.log('val is null')
      return ''
    } else if (typeof val.data !== 'object') {
      try {
        val = JSON.parse(val.data)
      } catch (err) {
        console.warn('value is not JSON')
        return val.data
      }

      return JSON.stringify(val.data, null, indent)
    }
  }
}

app.config.devtools = process.env.NODE_ENV === 'development' // https://devtools.vuejs.org/
app.config.warnHandler = function (err, vm, info) {
  console.log(err)
  console.log(info)
  console.log(vm)
}

app.mount('#app')

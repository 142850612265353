// Components that need to be available in the rails templates
import { defineAsyncComponent } from 'vue'

export default {
  AccountsForm: defineAsyncComponent(() => import('./fall_leases/accounts_form.vue')),
  Benchmarking: defineAsyncComponent(() => import('./benchmarking/main.vue')),
  AdminUserTable: defineAsyncComponent(() => import('./admin_user_table.vue')),
  ChartsAveragePrices: defineAsyncComponent(() => import('./charts/average_prices.vue')),
  ChartsDashboardVolume: defineAsyncComponent(() => import('./charts/dashboard_volume.vue')),
  ChartsElcert: defineAsyncComponent(() => import('./charts/elcert.vue')),
  ChartsMarketPrices: defineAsyncComponent(() => import('./charts/market_prices.vue')),
  ChartsReservoirFill: defineAsyncComponent(() => import('./charts/reservoir_fill.vue')),
  ChartsPortfolioPercentResult: defineAsyncComponent(() => import('./charts/portfolio/percent_result.vue')),
  ChartsPortfolioSortedResult: defineAsyncComponent(() => import('./charts/portfolio/sorted_result.vue')),
  ChartsPortfolioScatter: defineAsyncComponent(() => import('./charts/portfolio/scatter.vue')),
  ChartsPowerPlantGeo: defineAsyncComponent(() => import('./charts/power_plant/geo.vue')),
  ChartsPowerPlantHistory: defineAsyncComponent(() => import('./charts/power_plant/history.vue')),
  ChartsPowerPlantLoad: defineAsyncComponent(() => import('./charts/power_plant/load.vue')),
  ChartsPowerPlantRunoff: defineAsyncComponent(() => import('./charts/power_plant/runoff.vue')),
  ChartsPowerPlantMeteogram: defineAsyncComponent(() => import('./charts/power_plant/meteogram.vue')),
  ChartsMonthlyResults: defineAsyncComponent(() => import('./charts/monthly_results.vue')),
  ChartsPowerPlantSpider: defineAsyncComponent(() => import('./charts/power_plant/spider.vue')),
  ChartsPowerPlantWeatherForecast: defineAsyncComponent(() => import('./charts/power_plant/weather_forecast.vue')),
  ChartsPowerPlantSolarHeatmap: defineAsyncComponent(() => import('./charts/power_plant/solar_heatmap.vue')),
  ChartsPowerPlantSolarKpiMonthly: defineAsyncComponent(() => import('./charts/power_plant/solar_kpi_monthly.vue')),
  ChartsPowerPlantWindKpiMonthly: defineAsyncComponent(() => import('./charts/power_plant/wind_kpi_monthly.vue')),
  ChartsPowerPlantMeasureTheorHourly: defineAsyncComponent(() => import('./charts/power_plant/measure_theor_hourly.vue')),
  ChartsPowerPlantPowerCurve: defineAsyncComponent(() => import('./charts/power_plant/power_curve.vue')),
  CompanyOwners: defineAsyncComponent(() => import('./company_owners.vue')),
  Components: defineAsyncComponent(() => import('./components.vue')),
  FallLeaseContractsTable: defineAsyncComponent(() => import('./fall_leases/fall_lease_contracts_table.vue')),
  ContractsAdminTable: defineAsyncComponent(() => import('./contracts/contracts_admin_table.vue')),
  ContractsEdit: defineAsyncComponent(() => import('./contracts/edit.vue')),
  PowerPlantWindRose: defineAsyncComponent(() => import('./power_plant_wind_rose.vue')),
  PowerPlantWindyMap: defineAsyncComponent(() => import('./power_plant_windy_map.vue')),
  DashboardPowerPlantList: defineAsyncComponent(() => import('./dashboard_power_plant_list.vue')),
  DataExportForm: defineAsyncComponent(() => import('./data_export_form.vue')),
  DowntimeTable: defineAsyncComponent(() => import('./downtime_table.vue')),
  DowntimePeriods: defineAsyncComponent(() => import('./downtime_periods/index.vue')),
  DynamicProgressCircular: defineAsyncComponent(() => import('./dynamic_progress_circular.vue')),
  ExpandableInfo: defineAsyncComponent(() => import('./expandable_info.vue')),
  FallLeases: defineAsyncComponent(() => import('./fall_leases/show.vue')),
  FallLeasesEdit: defineAsyncComponent(() => import('./fall_leases/edit.vue')),
  FileImport: defineAsyncComponent(() => import('./file_import.vue')),
  HourlyValues: defineAsyncComponent(() => import('./hourly_values.vue')),
  Navigation: defineAsyncComponent(() => import('./navigation/index.vue')),
  NewsArchiveFiltering: defineAsyncComponent(() => import('./news_items/archive_filtering.vue')),
  NewsItemAccessSelection: defineAsyncComponent(() => import('./news_items/access_selection.vue')),
  NewsItemCard: defineAsyncComponent(() => import('./news_items/card.vue')),
  NewsItemIcons: defineAsyncComponent(() => import('./news_items/icons.vue')),
  NewsItems: defineAsyncComponent(() => import('./news_items/news_items.vue')),
  NewsWidget: defineAsyncComponent(() => import('./news_items/news_widget.vue')),
  NvePlantsTable: defineAsyncComponent(() => import('./nve_plants_table.vue')),
  NveShow: defineAsyncComponent(() => import('./nve/show.vue')),
  OptionalFields: defineAsyncComponent(() => import('./optional_fields.vue')),
  OverviewMap: defineAsyncComponent(() => import('./overview_map/index.vue')),
  OmOverview: defineAsyncComponent(() => import('./om_dashboard/overview.vue')),
  OmDrilldownCommon: defineAsyncComponent(() => import('./om_dashboard/drilldown.vue')),
  OmRecentAlarms: defineAsyncComponent(() => import('./om_dashboard/recent_alarms.vue')),
  OmSensorMapping: defineAsyncComponent(() => import('./om_dashboard/elements/sensor_mapping.vue')),
  OmTimeScope: defineAsyncComponent(() => import('./om_dashboard/elements/time_scope.vue')),
  OverviewPowerPlantList: defineAsyncComponent(() => import('./overview/index.vue')),
  OverviewSortSelector: defineAsyncComponent(() => import('./navigation/overview_sort_selector.vue')),
  Paginate: defineAsyncComponent(() => import('./paginate.vue')),
  PortfolioTable: defineAsyncComponent(() => import('./portfolio_table.vue')),
  PowerPlantEditButton: defineAsyncComponent(() => import('./power_plants/edit_button.vue')),
  PowerPlantsAdminTable: defineAsyncComponent(() => import('./power_plants/admin_table.vue')),
  PowerPlantNav: defineAsyncComponent(() => import('./navigation/power_plant_nav.vue')),
  PowerPlantSelection: defineAsyncComponent(() => import('./power_plant_selection.vue')),
  PowerPlantsEditTable: defineAsyncComponent(() => import('./power_plants/edit_table.vue')),
  MarketplaceProducts: defineAsyncComponent(() => import('./marketplace_products/index.vue')),
  SearchableTreeview: defineAsyncComponent(() => import('./searchable_treeview.vue')),
  SimpleDialog: defineAsyncComponent(() => import('./simple_dialog.vue')),
  WorkItems: defineAsyncComponent(() => import('./work_items/index.vue')),
  WorkItemStatistics: defineAsyncComponent(() => import('./work_items/statistics/index.vue')),
  Tooltip: defineAsyncComponent(() => import('./tooltip.vue')),
  UserAccessLevel: defineAsyncComponent(() => import('./user_access_level.vue')),
  UserCard: defineAsyncComponent(() => import('./common/user_card.vue')),
  VDatePickerInput: defineAsyncComponent(() => import('./v_date_picker_input.vue')),
  VHighcharts: defineAsyncComponent(() => import('./v_highcharts.vue')),
  VMultiSelect: defineAsyncComponent(() => import('./v_multi_select.vue')),
  VRadioGroupModified: defineAsyncComponent(() => import('./v_radio_group_modified.vue')),
  VSearchableMultiSelect: defineAsyncComponent(() => import('./v_searchable_multi_select.vue')),
  VSearchableSelect: defineAsyncComponent(() => import('./v_searchable_select.vue')),
  VTimePickerInput: defineAsyncComponent(() => import('./v_time_picker_input.vue')),
  MarkdownEditor: defineAsyncComponent(() => import('./markdown_editor.vue')),
  ChartsBudgetProfile: defineAsyncComponent(() => import('./charts/budget_profile/results.vue')),
  BudgetProfileTable: defineAsyncComponent(() => import('./budget_profile_table.vue')),
  RevenueProfileTable: defineAsyncComponent(() => import('./revenue_profile_table.vue')),
  PriceProfileTable: defineAsyncComponent(() => import('./price_profile_table.vue')),
  ChartsAgeDistribution: defineAsyncComponent(() => import('./charts/portfolio/age_distribution.vue')),
  ChartsPowerPlantsYearlyLoadHour: defineAsyncComponent(() => import('./charts/portfolio/power_plants_yearly_load_hour.vue')),
  FeatureRequestFiltering: defineAsyncComponent(() => import('./feature_requests/filtering.vue')),
  FeatureRequestTable: defineAsyncComponent(() => import('./feature_requests/table.vue')),
  ChartsPortfolioHourlyResult: defineAsyncComponent(() => import('./charts/portfolio/hourly_result.vue')),
  ChartsPortfolioProductionRevenue: defineAsyncComponent(() => import('./charts/portfolio/production_revenue.vue')),
  NavSelector: defineAsyncComponent(() => import('./navigation/nav_selector.vue')),
  ChartsMarketOutlookForwards: defineAsyncComponent(() => import('./charts/market_outlook/forwards.vue')),
  MarketOutlookIndex: defineAsyncComponent(() => import('./market_outlook/index.vue')),
  FollowUp: defineAsyncComponent(() => import('./follow_up/follow_up.vue')),
  ResponsiblesIndex: defineAsyncComponent(() => import('./responsibles/index.vue')),
  ResponsiblesShow: defineAsyncComponent(() => import('./responsibles/show.vue')),
  PpaPrices: defineAsyncComponent(() => import('./ppa_prices/ppa_prices.vue')),
  Liquidity: defineAsyncComponent(() => import('./liquidity/liquidity.vue')),
  LiquidityMinMaxExpectedChart: defineAsyncComponent(() => import('./liquidity/min_max_expected_chart.vue')),
  CreateBudgetForecastsDialog: defineAsyncComponent(() => import('../components/create_budget_forecasts_dialog.vue')),
  Responsible: defineAsyncComponent(() => import('./responsibles/responsible.vue')),
  VMonthRangePicker: defineAsyncComponent(() => import('./v_month_range_picker.vue')),
  TimeSeriesImportForm: defineAsyncComponent(() => import('./time_series_import_form.vue')),
  ProductionSimulationPlanCharts: defineAsyncComponent(() => import('./production_simulation_plan/charts.vue')),
  ProductionSimulationPlanQueryButton: defineAsyncComponent(() => import('./production_simulation_plan/query_button.vue')),
  PageHitsTable: defineAsyncComponent(() => import('./page_hits/page_hits_table.vue')),
  SolarInverterTypes: defineAsyncComponent(() => import('./power_plants/form/solar/inverter_types.vue')),
  SolarModuleTypes: defineAsyncComponent(() => import('./power_plants/form/solar/module_types.vue')),
  CommercialScada: defineAsyncComponent(() => import('./commercial_scada/commercial_scada.vue')),
  YearMonthNav: defineAsyncComponent(() => import('./navigation/year_month_nav.vue')),
  LoginForm: defineAsyncComponent(() => import('./navigation/login_form.vue')),
  ResetPasswordForm: defineAsyncComponent(() => import('./navigation/reset_password_form.vue')),
  FallLeaseLandownerInfo: defineAsyncComponent(() => import('./power_plants/fall_lease_landowner_info.vue')),
  WindTurbineTypes: defineAsyncComponent(() => import('./power_plants/form/wind/turbine_types.vue')),
  WindPowerCurves: defineAsyncComponent(() => import('./power_plants/form/wind/power_curves.vue')),
  WindEventMappings: defineAsyncComponent(() => import('./power_plants/form/wind/event_mappings.vue'))
}

import { defineComponent, h } from 'vue'
import qs from 'qs'
import { createRouter, createMemoryHistory } from 'vue-router'
import NotificationSettingsPersonal from '../components/navigation/notification_settings/personal_settings.vue'
import NotificationSettingsGeneral from '../components/navigation/notification_settings/general_settings.vue'
import NotificationSettingsMenuItems from '../components/navigation/notification_settings/menu_items.vue'
import NotificationSettingsRuleForm from '../components/navigation/notification_settings/rule_form.vue'

const router = createRouter({
  // Use createMemoryHistory for a non-URL-based (abstract) history
  history: createMemoryHistory(),
  parseQuery: qs.parse,
  stringifyQuery: qs.stringify,
  routes: [
    {
      path: '',
      name: 'empty-route',
      component: defineComponent({
        name: 'EmptyRoute',
        render() {
          return h('div')
        }
      })
    },
    {
      path: '/notification_settings_menu_items',
      name: 'notification_settings_menu_items',
      component: NotificationSettingsMenuItems,
      props: (route) => ({
        current_user: route.query.current_user
      })
    },
    {
      path: '/notification_settings_personal',
      name: 'notification_settings_personal',
      component: NotificationSettingsPersonal,
      props: (route) => ({
        current_user: route.query.current_user
      })
    },
    {
      path: '/notification_settings_general',
      name: 'notification_settings_general',
      component: NotificationSettingsGeneral,
      props: (route) => ({
        current_user: route.query.current_user
      })
    },
    {
      path: '/notification_settings_rule',
      name: 'notification_settings_rule',
      component: NotificationSettingsRuleForm,
      props: (route) => ({
        current_rule: route.query.rule,
        power_plants: route.query.power_plants,
        responsibles: route.query.responsibles,
        operational_status_types: route.query.operational_status_types,
        currency_codes: route.query.currency_codes
      })
    }
  ]
})

export default router

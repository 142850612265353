<script>
import NotificationSettingsBus from '../../../event_bus/index.js'

export default {
  name: 'NotificationSettingsPersonal',
  props: {
    current_user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rule_responsibles: [],
      formErrors: {},
      loading: true
    }
  },
  methods: {
    toggleActive(rule_responsible, index) {
      const apiUrl = `/notification_rule_responsibles/${rule_responsible.id}`
      this.formErrors = {}
      this.axios
        .put(apiUrl, { active: rule_responsible.active })
        .then((response) => {
          this.handleSaveSuccess(response.data)
        })
        .catch((error) => {
          this.rule_responsibles[index].active = !this.rule_responsibles[index].active

          if (error.response && error.response.data)
            this.formErrors[rule_responsible.rule_name] = error.response.data
        })
    },
    handleSaveSuccess(savedRuleResponsible) {
      const index = this.rule_responsibles.findIndex((item) => item.id === savedRuleResponsible.id)
      if (index !== -1) this.rule_responsibles[index] = savedRuleResponsible

      NotificationSettingsBus.$emit('save-rule-responsible-success', savedRuleResponsible)
    },
    fetchRuleResponsibles() {
      const apiUrl = '/notification_rule_responsibles'
      this.loading = true
      this.axios
        .get(apiUrl)
        .then((response) => {
          this.rule_responsibles = response.data
          this.loading = false
        })
        .catch((error) => {
          console.error(error)
          this.loading = false
        })
    },
    goBack() {
      this.$router.go(-1)
    }
  },
  created() {
    this.fetchRuleResponsibles()
    NotificationSettingsBus.$on('save-rule-success', this.fetchRuleResponsibles)
  },
  beforeUnmount() {
    NotificationSettingsBus.$off('save-rule-success', this.fetchRuleResponsibles)
  }
}
</script>

<template lang="pug">
v-card
  v-btn(icon color="#344054" @click="goBack")
    v-icon mdi-chevron-left
  v-card-title {{ $t('notification_system.personal_notifications', { name: current_user.short_name }) }}
  v-divider
  v-card-text
    div(v-if="Object.keys(formErrors).length > 0")
      v-alert(v-for="(errors, ruleName) in formErrors" :key="ruleName" type="warning")
        div(v-for="(error, index) in errors.base" :key="index") {{ ruleName }}: {{ error }}
    div(v-if="!loading && rule_responsibles.length === 0")
      | {{ $t('notification_system.no_scheduled_notifications') }}
    v-list(density="compact" v-if="!loading && rule_responsibles.length > 0")
      v-list-item(v-for="(rule_responsible, index) in rule_responsibles" :key="rule_responsible.id")
        v-list-item-title {{ rule_responsible.rule_name }}
        v-list-item-action(class="align-self-center")
          v-tooltip(location="bottom")
            template(v-slot:activator="{ props }")
              v-switch(
                v-model="rule_responsible.active"
                @click.stop="toggleActive(rule_responsible, index)"
                inset
                color="info"
                v-bind="props"
              )
            span {{ rule_responsible.active ? $t('notification_system.turn_off_email_notification') : $t('notification_system.turn_on_email_notification') }}
</template>

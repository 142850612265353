import { createI18n } from 'vue-i18n'


import en from '../translations/en.json'
import nb from '../translations/nb.json'
import fr from '../translations/fr.json'
import de from '../translations/de.json'

const messages = { en, nb, fr, de }

const i18n = createI18n({
  legacy: false, // you must set `legacy: false` to use the Composition API
  locale: window.vue_lang || 'en', // default to 'en' if window.vue_lang is not set
  fallbackLocale: 'en',
  messages,
})

export default i18n

import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import i18n from './vue_i18n'
import * as components from 'vuetify/components'
import * as labsComponents from 'vuetify/labs/components'
import * as directives from 'vuetify/directives'
import { en, no, fr, de } from 'vuetify/locale'

export default createVuetify({
  components: {
    ...components,
    ...labsComponents,
    directives,
  },
  lang: {
    locales: { en, no, fr, de },
    current: i18n.global.locale.value, // Accessing locale value in Vue 3 i18n
  },
  theme: {
    themes: {
      light: {
        primary: '#132634',
      },
    },
  },
})

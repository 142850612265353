import mitt from 'mitt'
const emitter = mitt()

function once(eventName, handler) {
  function wrapped(...args) {
    handler(...args)
    emitter.off(eventName, wrapped)
  }
  emitter.on(eventName, wrapped)
}

export default {
  $on: emitter.on,
  $off: emitter.off,
  $emit: emitter.emit,
  $once: once
}

import highchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
import loadBullet from 'highcharts/modules/bullet'
import loadSolidGauge from 'highcharts/modules/solid-gauge'
import loadBoost from 'highcharts/modules/boost'
import HighchartsHeatmap from 'highcharts/modules/heatmap'
import exportingInit from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'

highchartsMore(Highcharts)
stockInit(Highcharts)
loadBullet(Highcharts)
loadSolidGauge(Highcharts)
loadBoost(Highcharts)
HighchartsHeatmap(Highcharts)
exportingInit(Highcharts)
exportData(Highcharts)

const highchartsOptions = {
  global: {
      time: {
          timezone: 'Europe/Oslo'
      }
  },
  title: {
    style: {
      fontWeight: 'normal'
    }
  },
  lang: JSON.parse(window.hs_lang),
  plotOptions: {
    series: {
      dataGrouping: {
        approximation: 'high'
      }
    }
  }
}

export function setupHighcharts() {
  Highcharts.setOptions(highchartsOptions)
}
